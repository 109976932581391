/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@tailwind base;
@tailwind components;
@tailwind utilities;

/** Ionic CSS Variables **/
:root {
	--ion-text-color: rgb(36, 36, 36);
	--ion-text-color-rgb: 36, 36, 36;

	--ion-color-primary: #2e7d32;
	--ion-color-primary-rgb: 33, 70, 135;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #60ad5e;
	--ion-color-primary-tint: #005005;

	--ion-color-secondary: #eede4d;
	--ion-color-secondary-rgb: 37, 141, 159;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary: #c9bc1f;
	--ion-color-secondary-tint: #c3b63c;

	--ion-color-tertiary: #4c329f;
	--ion-color-tertiary-rgb: 76, 50, 159;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #432c8c;
	--ion-color-tertiary-tint: #5e47a9;

	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

/*
   * Dark Colors
   * -------------------------------------------
   */
body.dark {
	--ion-color-primary: #2e7d32;
	--ion-color-primary-rgb: 33, 70, 135;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #60ad5e;
	--ion-color-primary-tint: #005005;

	--ion-color-secondary: #eede4d;
	--ion-color-secondary-rgb: 37, 141, 159;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 0, 0, 0;
	--ion-color-secondary: #c9bc1f;
	--ion-color-secondary-tint: #c3b63c;

	--ion-color-tertiary: #4c329f;
	--ion-color-tertiary-rgb: 76, 50, 159;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #432c8c;
	--ion-color-tertiary-tint: #5e47a9;

	--ion-color-success: #2fdf75;
	--ion-color-success-rgb: 47, 223, 117;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0, 0, 0;
	--ion-color-success-shade: #29c467;
	--ion-color-success-tint: #44e283;

	--ion-color-warning: #ffd534;
	--ion-color-warning-rgb: 255, 213, 52;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0bb2e;
	--ion-color-warning-tint: #ffd948;

	--ion-color-danger: #ff4961;
	--ion-color-danger-rgb: 255, 73, 97;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #e04055;
	--ion-color-danger-tint: #ff5b71;

	--ion-color-dark: #f4f5f8;
	--ion-color-dark-rgb: 244, 245, 248;
	--ion-color-dark-contrast: #000000;
	--ion-color-dark-contrast-rgb: 0, 0, 0;
	--ion-color-dark-shade: #d7d8da;
	--ion-color-dark-tint: #f5f6f9;

	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	--ion-color-light: #282a30;
	--ion-color-light-rgb: 34, 36, 40;
	--ion-color-light-contrast: #ffffff;
	--ion-color-light-contrast-rgb: 255, 255, 255;
	--ion-color-light-shade: #1e2023;
	--ion-color-light-tint: #383a3e;

	--ion-item-background: var(--ion-color-light);
	--ion-card-background: var(--ion-color-light);
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
	--ion-background-color: var(--ion-color-light);
	--ion-background-color-rgb: var(--ion-color-light-rgb);

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-color-step-50: #0d0d0d;
	--ion-color-step-100: #1a1a1a;
	--ion-color-step-150: #262626;
	--ion-color-step-200: #333333;
	--ion-color-step-250: #404040;
	--ion-color-step-300: #4d4d4d;
	--ion-color-step-350: #595959;
	--ion-color-step-400: #666666;
	--ion-color-step-450: #737373;
	--ion-color-step-500: #808080;
	--ion-color-step-550: #8c8c8c;
	--ion-color-step-600: #999999;
	--ion-color-step-650: #a6a6a6;
	--ion-color-step-700: #b3b3b3;
	--ion-color-step-750: #bfbfbf;
	--ion-color-step-800: #cccccc;
	--ion-color-step-850: #d9d9d9;
	--ion-color-step-900: #e6e6e6;
	--ion-color-step-950: #f2f2f2;

	--ion-item-background: var(--ion-color-light);
	--ion-card-background: var(--ion-color-light);
}

.ios ion-modal {
	--ion-background-color: var(--ion-color-step-100);
	--ion-toolbar-background: var(--ion-color-step-150);
	--ion-toolbar-border-color: var(--ion-color-step-250);
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
	--ion-background-color: var(--ion-color-light);
	--ion-background-color-rgb: var(--ion-color-light-rgb);

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-border-color: #222222;

	--ion-color-step-50: #1e1e1e;
	--ion-color-step-100: #2a2a2a;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;

	--ion-item-background: var(--ion-color-light);

	/* --ion-toolbar-background: #1f1f1f;

	--ion-tab-bar-background: #1f1f1f;

	--ion-card-background: #1e1e1e; */
}

ion-split-pane {
	--side-max-width: 200px;
}

.input-error {
	--border-color: var(--ion-color-danger) !important;
}

.input-required:after {
	content: ' *';
	color: var(--ion-color-danger);
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
	margin-top: 5px !important;
}

body {
	background-image: linear-gradient(
		to bottom,
		rgba(var(--ion-color-secondary-rgb), 0.1),
		rgba(var(--ion-color-light-rgb), 0.1)
	);
}

a {
	color: var(--ion-text-color) !important;
}
.MuiBreadcrumbs-separator {
	color: var(--ion-text-color) !important;
}

ion-item {
	--border-color: rgba(var(--ion-text-color-rgb), 0.4) !important;
}

html {
	background-color: white;
}

ion-list-header {
	font-size: 18px;
}

ion-card-title.action-button {
	margin-right: 28px;
}

.wide-alert {
	--width: 400px;
	--min-width: 400px;
}

.large-modal {
	--min-height: 90%;
	--min-width: 60%;
}

.condense-list ion-item {
	--min-height: unset;
	line-height: 1;
}

.condense-list ion-label {
	margin: 5px 0px 5px 5px;
}

.empty-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100%;
	width: 100%;
}

.empty-image-container {
	max-width: 400px;
	max-height: 250px;
}

.empty-title {
	margin: 10px;
	font-size: larger;
}

.empty-sub {
	font-size: smaller;
}

.empty-divider {
	width: 50px;
	margin: 10px;
	border-bottom: solid 1px rgba(var(--ion-text-color-rgb), 0.7);
}

.filter-badge {
	position: absolute;
	top: 0px;
	right: -5px;
	font-size: 8px;
	--padding-start: 4px;
	--padding-end: 4px;
}

.loading-route {
	height: 90%;
	width: 90%;
	margin: auto;
	flex: 1;
	min-height: 600px;
}

li button.MuiButtonBase-root {
	background: unset;
}

.MuiAccordionSummary-root {
	background-color: rgba(var(--ion-color-medium-rgb), 0.1) !important;
}

.MuiAccordionSummary-root.Mui-focusVisible {
	background-color: rgba(var(--ion-color-primary-rgb), 0.1) !important;
}

.modal-transition {
	transition: width 2s;
}

.hidden-input {
	visibility: hidden;
	height: 0px;
	width: 0px;
}

body.dark .MuiPaper-root {
	background-color: var(--ion-background-color);
}

.rw-forecast-icon svg {
	margin: auto;
}

ion-card.ios {
	margin: 10px !important;
}

.MuiStepper-root {
	margin-top: 5px;
}

.mui-item {
	--padding-start: 0;
	--inner-padding-end: 0;
}
.mui-item .MuiInputLabel-root {
	padding-left: 22px;
	padding-top: 10px;
}
.mui-item input {
	padding-left: 15px;
}

.wide-popover {
	--min-width: 250px;
}

#gantt-container > div {
	overflow: unset !important;
}

.chart-card {
	padding: 0;
}
