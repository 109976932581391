.page-bar {
	position: absolute;
	height: calc(100% - 56px);
	width: 100%;
	overflow: hidden;
}

.page-title {
	font-size: x-large;
}
.page-toolbar-buttons {
	flex: 2;
	flex-wrap: wrap;
}

.page-toolbar-buttons ion-button {
	text-transform: none;
}

.page-toolbar-title {
	margin: 0;
	padding: 0 0 0 8px;
	z-index: 1;
}

.page-toolbar-content {
	flex: 1;
	display: flex;
	flex-direction: column;

	justify-content: space-between;
}

.page-top {
	margin: 15px;
	display: flex;
	justify-content: space-between;
	height: 44px;
}

.page-icon {
	width: 32px;
	height: 32px;
	font-size: xx-large;
	margin: 10px;
	color: var(--ion-color-secondary);
}

.page-icon svg {
	width: 32px;
	height: 32px;
	font-size: xx-large;
	margin: 10px;
	color: var(--ion-color-primary);
	margin-top: -15px;
	margin-left: 0px;
}

.page-container {
	overflow: auto;
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 0 10px 0 10px;
}

.page-root {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-image: linear-gradient(
		to bottom,
		rgba(var(--ion-color-secondary-rgb), 0.03),
		rgba(var(--ion-color-light-rgb), 0.1)
	);
}

body.dark .page-root {
	background-image: linear-gradient(
		to bottom,
		rgba(var(--ion-color-secondary-rgb), 0.03),
		rgba(var(--ion-color-light-rgb), 1)
	);
}

.page-header.header-md::after {
	background-image: none;
}

.page-toolbar {
	width: 100%;
	align-items: center;
	margin: 10px 0 10px 0;
	--background: none;
	display: flex;
	flex-direction: row;
	border-bottom: solid 1px rgba(var(--ion-color-secondary-rgb), 0.4);
}

.page-header {
	background: none;
	color: var(--ion-text-color);
}
.breadcrumb {
	font-size: larger;
}

.loading-container {
	display: block;
}
.loading-page-rect {
	max-width: 100%;
	width: 100%;
}
