.expand-search-bar {
	min-height: 58px;
	max-width: 350px;
}
.expand-search-button {
	height: 32px;
}

.expand-collapsed {

}
