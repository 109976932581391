.menu-header {
	display: flex;
	flex-direction: row;
	border-bottom: solid 1px rgba(var(--ion-text-color-rgb), 0.5);
	padding: 10px;
}

.menu-header img {
	max-width: 100%;
}

.menu-header-title {
	margin-left: 10px;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.menu-title {
	font-size: x-large;
}

.menu-title-sub {
	font-size: smaller;
}

.menu-content {
	--padding-start: 0 !important;
	--padding-end: 0 !important;
}
