.profile-popover-root {
	display: flex;
	flex-direction: column;
}

.user-container {
	margin: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.user-name-org-container {
	display: flex;
	flex: 1;
	margin-left: 10px;
	flex-direction: column;
	justify-content: space-between;
}

.user-name {
	font-size: larger;
}

.user-org {
	margin-top: 2px;
	font-size: smaller;
}
