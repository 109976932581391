.invite-empty-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	height: 100%;
	width: 100%;
}

.invite-empty-image-container {
	max-width: 400px;
	max-height: 250px;
}

.invite-empty-title {
	font-size: larger;
}

.invite-empty-sub {
	font-size: smaller;
}
